/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
    isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
    title: "Harsh's Portfolio",
    description: "A passionate software developer with a strong interest in big data and cloud computing.",
    og: {
        title: "Harsh Victor Challa's Portfolio",
        type: "website",
        url: "http://www.harshchalla.me/",
    },
};

//Home Page
const greeting = {
    title: "Harsh Victor Challa",
    logo_name: "Harsh Victor Challa",
    nickname: "",
    subTitle: "A passionate software developer with a strong interest in big data and cloud computing.",
    resumeLink: "https://drive.google.com/file/d/1gbN51CEDTjegZjNrTxNZPe_0YhKVApJR/view?usp=sharing",
    portfolio_repository: "https://github.com/Harshchalla?tab=repositories",
    githubProfile: "https://github.com/Harshchalla",
};

const socialMediaLinks = [
    /* Your Social Media Link */
    // github: "https://github.com/ashutosh1919",
    // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
    // gmail: "ashutoshhathidara98@gmail.com",
    // gitlab: "https://gitlab.com/ashutoshhathidara98",
    // facebook: "https://www.facebook.com/laymanbrother.19/",
    // twitter: "https://twitter.com/ashutosh_1919",
    // instagram: "https://www.instagram.com/layman_brother/"

    {
        name: "Github",
        link: "https://github.com/Harshchalla/",
        fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
        backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
    },
    {
        name: "LinkedIn",
        link: "https://www.linkedin.com/in/harsh-challa/",
        fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
        backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
    },
    //{
    // name: "YouTube",
    //link: "https://youtube.com/c/DevSense19",
    // fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    // backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
    // },
    {
        name: "Gmail",
        link: "mailto:harshchalla24@gmail.com",
        fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
        backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
    },
    {
        name: "X-Twitter",
        link: "https://X.com/harshchala",
        fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
        backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
    },
    //{
    //  name: "Facebook",
    //   link: "https://www.facebook.com/laymanbrother.19/",
    //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    //  backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
    // },
    // {
    //    name: "Instagram",
    //    link: "https://www.instagram.com/layman_brother/",
    //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
    // },
];

const skills = {
    data: [{
            title: "Data Engineering & AI",
            fileName: "DataEngineeringImg",
            skills: [
                "💡 Developing scalable data pipelines and real-time processing systems for diverse use cases",
                "💡 Hands-on experience with statistical analysis, time series forecasting, and machine learning models",
                "💡 Experience in working with Machine Learning and real-time data streaming solutions",
            ],
            softwareSkills: [{
                    skillName: "Kafka",
                    fontAwesomeClassname: "simple-icons:apachekafka",
                    style: {
                        backgroundColor: "transparent",
                    },
                },

                {
                    skillName: "Python",
                    fontAwesomeClassname: "ion-logo-python",
                    style: {
                        backgroundColor: "transparent",
                        color: "#3776AB",
                    },
                },
                {
                    skillName: "Apache Spark",
                    fontAwesomeClassname: "simple-icons:apachespark",
                    style: {
                        color: "#E25A1C",
                    },
                },
                {
                    skillName: "AWS Glue",
                    fontAwesomeClassname: "simple-icons:amazonaws",
                    style: {
                        color: "#FF9900",
                    },
                },
            ],
        },
        {
            title: "Software Development",
            fileName: "SoftwareDevelopmentImg",
            skills: [
                "💡 Building backend services and APIs using Python, Flask, and Node.js",
                "💡 Developing data-driven applications and systems using SQL, PostgreSQL, and NoSQL databases",
                "💡 Experience in version control systems and collaborative development using Git",
            ],
            softwareSkills: [{
                    skillName: "Python",
                    fontAwesomeClassname: "ion-logo-python",
                    style: {
                        color: "#3776AB",
                    },
                },
                {
                    skillName: "Flask",
                    fontAwesomeClassname: "simple-icons:flask",
                    style: {
                        color: "#000000",
                    },
                },
                {
                    skillName: "SQL",
                    fontAwesomeClassname: "simple-icons:mysql",
                    style: {
                        color: "#00758F",
                    },
                },
                {
                    skillName: "PostgreSQL",
                    fontAwesomeClassname: "simple-icons:postgresql",
                    style: {
                        color: "#336791",
                    },
                },
                {
                    skillName: "NodeJS",
                    fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
                    style: {
                        color: "#339933",
                    },
                },
                {
                    skillName: "Git",
                    fontAwesomeClassname: "simple-icons:git",
                    style: {
                        color: "#F05032",
                    },
                },
            ],
        },
        {
            title: "Cloud Infrastructure & DevOps",
            fileName: "CloudInfraImg",
            skills: [
                "💡 Extensive experience working with cloud platforms such as AWS with services like EC2, S3, SQS, Lambda, Glue, Athena, and QuickSight.",
                "💡 Hosting, maintaining, and scaling applications using EC2, S3, and Kubernetes",
                "💡 Implementing CI/CD pipelines, containerization, and orchestration using Docker and Kubernetes",
            ],
            softwareSkills: [{
                    skillName: "AWS",
                    fontAwesomeClassname: "simple-icons:amazonaws",
                    style: {
                        color: "#FF9900",
                    },
                },
                {
                    skillName: "Google Cloud Platform",
                    fontAwesomeClassname: "simple-icons:googlecloud",
                    style: {
                        color: "#4285F4",
                    },
                },
                {
                    skillName: "Docker",
                    fontAwesomeClassname: "simple-icons:docker",
                    style: {
                        color: "#1488C6",
                    },
                },
                {
                    skillName: "Kubernetes",
                    fontAwesomeClassname: "simple-icons:kubernetes",
                    style: {
                        color: "#326CE5",
                    },
                },
                {
                    skillName: "AWS Lambda",
                    fontAwesomeClassname: "simple-icons:aws",
                    style: {
                        color: "#FF9900",
                    },
                },
            ],
        },
        {
            title: "Data Analytics & Visualization",
            fileName: "DataAnalyticsImg",
            skills: [
                "💡 Expertise in data analysis using SQL, Python, and data visualization tools like Tableau",
                "💡 Experience in building dashboards and data-driven decision-making tools",
                "💡 Leveraging statistical methods for forecasting, anomaly detection, and KPI analysis",
            ],
            softwareSkills: [{
                    skillName: "Tableau",
                    fontAwesomeClassname: "simple-icons:tableau",
                    style: {
                        color: "#E97627",
                    },
                },
                {
                    skillName: "Pandas",
                    fontAwesomeClassname: "simple-icons:pandas",
                    style: {
                        color: "#150458",
                    },
                },
                {
                    skillName: "Numpy",
                    fontAwesomeClassname: "simple-icons:numpy",
                    style: {
                        color: "#013243",
                    },
                },
                {
                    skillName: "SQL",
                    fontAwesomeClassname: "simple-icons:mysql",
                    style: {
                        color: "#00758F",
                    },
                },
                {
                    skillName: "Matplotlib",
                    fontAwesomeClassname: "simple-icons:matplotlib",
                    style: {
                        color: "#11557C",
                    },
                },
            ],
        },
    ],
};

// Education Page
const competitiveSites = {
    competitiveSites: [{
            siteName: "LeetCode",
            iconifyClassname: "simple-icons:leetcode",
            style: {
                color: "#F79F1B",
            },
            profileLink: "https://leetcode.com/layman_brother/",
        },
        {
            siteName: "HackerRank",
            iconifyClassname: "simple-icons:hackerrank",
            style: {
                color: "#2EC866",
            },
            profileLink: "https://www.hackerrank.com/layman_brother",
        },
        {
            siteName: "Codechef",
            iconifyClassname: "simple-icons:codechef",
            style: {
                color: "#5B4638",
            },
            profileLink: "https://www.codechef.com/users/ashutosh_1919",
        },
        {
            siteName: "Codeforces",
            iconifyClassname: "simple-icons:codeforces",
            style: {
                color: "#1F8ACB",
            },
            profileLink: "http://codeforces.com/profile/layman_brother",
        },
        {
            siteName: "Hackerearth",
            iconifyClassname: "simple-icons:hackerearth",
            style: {
                color: "#323754",
            },
            profileLink: "https://www.hackerearth.com/@ashutosh391",
        },
        {
            siteName: "Kaggle",
            iconifyClassname: "simple-icons:kaggle",
            style: {
                color: "#20BEFF",
            },
            profileLink: "https://www.kaggle.com/laymanbrother",
        },
    ],
};

const degrees = {
    degrees: [{
            title: "Arizona State University",
            subtitle: "M.S. in Computer Science",
            logo_path: "Asulogo.png",
            alt_name: "ASU",
            duration: "2023 - 2025",
            descriptions: [
                "🎓 Focused on advanced topics such as Data Mining, Data Visualization, Distributed Systems, Cloud Computing, and Statistical Machine Learning.",
                "🎓 Maintained a perfect GPA of 4.0",
                "",
            ],
            relevant_courses: [
                "🎓 Big Data Analytics",
                "🎓 Distributed Systems",
                "🎓 Cloud Computing",
                "🎓 Statistical Machine Learning",
                "🎓 Data Visualization",
                "🎓 Data Mining",
            ],
            website_link: "https://www.asu.edu/",
        },
        {
            title: "Visvesvaraya Technological University",
            subtitle: "B.E. in Computer Science",
            logo_path: "vtulogo.png",
            alt_name: "VTU Bengaluru",
            duration: "2019 - 2023",
            descriptions: [
                "🎓 Studied core subjects such as Data Structures, Algorithms, DBMS, Operating Systems, Artificial Intelligence, and Machine Learning.",
                "🎓 Completed projects on data engineering, cloud computing, and machine learning, applying theoretical knowledge to real-world applications.",
                "🎓 Participated in various workshops and hackathons, gaining hands-on experience with cloud platforms and big data technologies.",
            ],
            relevant_courses: [
                "🎓 Data Structures and Algorithms",
                "🎓 Artificial Intelligence and Machine Learning",
                "🎓 Distributed Database Systems",
                "🎓 Big Data Analytics",
                "🎓 Cloud Computing",
                "🎓 Data Mining and Data Visualization",
            ],
            website_link: "https://vtu.ac.in/",
        },
    ],
};

const certifications = {
    certifications: [{
            title: "Oracle Certified Cloud Infrastructure 2024 Generative AI Professional",
            subtitle: "- Oracle",
            logo_path: "oraclelogo.png",
            certificate_link: "https://catalog-education.oracle.com/pls/certview/sharebadge?id=26B4EF3FFC25801278C84D509830D89E7D8F142967C296E7ECF96115F4FB7338",
            alt_name: "Oracle",
            color_code: "#ffffff",
        },
        {
            title: "AWS Certified Academy Graduate - AWS Academy Cloud Foundations",
            subtitle: "- AWS",
            logo_path: "awslogo.png",
            certificate_link: "https://www.credly.com/badges/f5e4e5dd-c0fa-485d-a227-01b54707889b/public_url",
            alt_name: "AWS",
            color_code: "#ffffff",
        },
    ],
};

// Experience Page
const experience = {
    title: "Experience",
    subtitle: "Work, Internship and Volunteership",
    description: "I have worked on a variety of machine learning, data analysis, and cloud development projects, utilizing my expertise in Python, SQL, and AWS. My focus has been on delivering scalable solutions that drive data-driven insights in both startup and academic environments.",
    header_image_path: "experience.svg",
    sections: [{
        title: "Work",
        work: true,
        experiences: [{
                "title": "Business and HR Analyst",
                "company": "Arizona State University",
                "company_url": "https://www.asu.edu/",
                "logo_path": "Asulogo.png",
                "duration": "November 2023 - Present",
                "location": "Tempe, AZ, USA",
                "description": [
                    "· Engineered VBA-embedded Python scripts that automated the manual process of formatting accounts data, achieving a 97.22% reduction in processing time from 12 hours to 20 minutes.",
                    "· Extracted, analyzed, and visualized reports from Workday and PeopleSoft in Tableau to enhance data-driven decision-making."
                ],
                "color": "#000000"
            },

            {
                title: "Machine Learning Intern",
                company: "Tequed Labs",
                company_url: "https://www.tequedlabs.com/",
                logo_path: "tequedlogo.jpeg",
                duration: "September 2022",
                location: "Bengaluru, KA, India",
                description: "· Led data cleaning and preprocessing initiatives for the house price prediction project, utilizing Python to handle missing values, outlier detection, and feature engineering, resulting in a model with 20% improved pricing accuracy in a tight timeline. \n\n" +
                    "· Orchestrated and optimized data pipelines using scikit-learn, incorporating GridSearchCV for hyperparameter tuning, achiev- ing price prediction accuracy of 91%.",
                color: "#0879bf",
            },


            {
                title: "Data Analyst Intern",
                company: "Cube Logistics",
                company_url: "#", // Add appropriate URL if available
                logo_path: "Cubelogo.png",
                duration: "October 2021 - December 2021",
                location: "Bengaluru, KA, India",
                description: "· Enhanced SQL query performance by optimizing SARGability, implementing composite indexing, and restructuring queries, resulting in a 50% reduction in execution times and improved efficiency in a Snowflake-based data warehouse. \n\n" +
                    "· Conducted comprehensive exploratory data analysis on large-scale logistics datasets, uncovering critical KPIs that were strategically integrated into Tableau dashboards, driving enhanced decision-making",
                color: "#9b1578",
            },
        ],
    }, ],
};

// Projects Page
const projectsHeader = {
    title: "Projects",
    description: "My projects leverage a wide array of cutting-edge technologies, ranging from web applications and cloud infrastructure to data engineering, analytics, and machine learning models. This diverse portfolio showcases my ability to build scalable solutions, utilizing the latest tools in software development, data science, and cloud computing.",
    avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
    title: "Publications",
    description: "Some of my published Articles.",
    avatar_image_path: "projects_image.svg",
};

const publications = {
    data: [{
            id: "159743",
            name: "Identification of Outliers Based on the Sensitivity of Data",
            createdAt: "05/2023",
            description: "Paper published in IJIRT ISSN: 2349-6002",
            url: "https://ijirt.org/Article?manuscript=159743",
        },
        {
            id: "229534",
            name: "Survey Paper on Outlier Detection Algorithms   ",
            createdAt: "01/2023",
            description: "Paper published in IJCRT ISSN:2320-2882",
            url: "https://docs.google.com/viewerng/viewer?url=http://www.ijcrt.org/papers/IJCRT2301124.pdf",
        },
    ],
};

// Contact Page
const contactPageData = {
    contactSection: {
        title: "Contact Me",
        profile_image_path: "animated_ashutosh.png",
        description: "I am Actively seeking full-time and co-op opportunities in software development, cloud engineering, and data-focused roles.",
    },
    blogSection: {
        title: "LinkedIn",
        subtitle: "I’m actively engaged in my professional journey and always open to meaningful connections. For inquiries or to get in touch, feel free to message me here on LinkedIn or via email.",
        link: "https://www.linkedin.com/in/harsh-challa/",
        avatar_image_path: "blogs_image.svg",
    },
    addressSection: {
        title: "Address",
        subtitle: "Tempe, Arizona, USA ",
        locality: "Tempe",
        country: "USA",
        region: "Arizona",
        postalCode: "85281",
        streetAddress: "",
        avatar_image_path: "address_image.svg",
        location_map_link: "https://maps.app.goo.gl/ngpu3ez7sRBheYnB7",
    },
    phoneSection: {
        title: "",
        subtitle: "",
    },
};

export {
    settings,
    seo,
    greeting,
    socialMediaLinks,
    skills,
    competitiveSites,
    degrees,
    certifications,
    experience,
    projectsHeader,
    publicationsHeader,
    publications,
    contactPageData,
};